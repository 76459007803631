import {config} from 'src/store';
const date = require('date-and-time');
const urlSlug = require('url-slug');

// GET localStorage
export function helper_localGet(key) {
  // const localGet = (localStorage.getItem(key) !== undefined) ? localStorage.getItem(key) : false;
  const localGet = (localStorage.getItem(key) !== undefined) ? JSON.parse(localStorage.getItem(key)) : false;
  return localGet;
}
// SET localStorage
export function helper_localSet(key, data) {
  let data_encrypt = JSON.stringify(data); //cryptr.encrypt(JSON.stringify(data));
  localStorage.setItem(key, data_encrypt);
}
// REMOVE localStorage
export function helper_removeSet(key) {
  localStorage.removeItem(key);
}
// controller - api - match
export function match_date_count(day) {
  // get date today.
  const now = new Date();
  const current = (date.format(date.addDays(now, day), 'M/D/YYYY'));
  return current;
}
// controller - api - match
export function date_time(day) {
  // get date today.
  const now = new Date();
  const current = (date.format(date.addDays(now, day), 'YYYY-MM-DD HH:mm:ss'));
  return current;
}
// controller - api - odds
export function date_parse(date_time) {
  date_time = date.format(new Date(date_time), 'YYYY-MM-DD HH:mm:ss');
  return date_time;
}
export function date_parse_day(date_time) {
  date_time = date.format(new Date(date_time), 'YYYY-MM-DD');
  return date_time;
}
export function get_domain() {
  let url = window.location.href;
  url = url.split('/');
  return url[0] + "//" + url[2];
}
export function date_day_count(count){
  let day = new Date();
  return date.format(date.addDays(day, count), 'YYYY-MM-DD');
}
export function date_day_counter(before, after){
  before = new Date(before);
  after = new Date(after);
  return date.subtract(after, before).toDays();
}

export function get_sub_domain() {
  let url = get_domain();
  url = url.split('/');
  url = url[2].split('.');
  return url[0];
}
export function get_lang() {
  let lang = window.location.href;
  lang = lang.split('/');
  return lang[3];
}

export function is_object_empty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function change_lang(lang){
  const url = window.location.href;
  let urlPath = url.split('/');
  config.lang = lang;
  urlPath[3] = lang;
  // urlPath.splice(3, 0, lang); // insert to index 3 - default language
  urlPath = urlPath.join('/');
  window.location.replace(urlPath);
}

export function helper_permalink(value){
  return urlSlug(value);
}

export function fixedNoRound(value) {
  let num = value;
  let with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  with2Decimals = Number(with2Decimals);
  // with2Decimals = parseFloat(with2Decimals).toFixed(2);
  return with2Decimals;
};
export async function paginate(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10,
    maxPages: number = 10
) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    // console.log(pages);
    let promise = new Promise(
      (resolve, reject)=>{
        let display = 8;
        let arr = []
        if(pages.length >= 1){
          pages.forEach(item=>{
            arr.push(item);
            if(item >= currentPage){
              resolve(arr);
            }
            if(arr.length >= display){
              if(arr.includes(currentPage) || item >= currentPage){
                resolve(arr);
              }
              arr = [];
            }
          })
        } else {
          resolve([])
        }
      }
    )

    // console.log(await promise);
    pages = await promise;

    // return object with all pager properties required by the view
    if(pages[0] < 1){
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: []
      }
    }
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}
// sort object
export async function sortObjectList (arr, prop) {
  prop = prop.split('.');
  var len = prop.length;

  arr.sort(function(a, b) {
    var i = 0;
    while (i < len) {
      a = a[prop[i]];
      b = b[prop[i]];
      i++;
    }
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  });
  return arr;
}

export function def_lang() {
  const url = window.location.href;
  let urlPath = url.split('/');
  // get language from url
  return urlPath[3];
}

export function getQueryParamByName(name){
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function setQueryParam(params){
  var esc = encodeURIComponent;
  var query = "?" + Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
  return query
}

export function getQueryParam(){
  var search = window.location.search.substring(1);
  if(search){
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
  }
  else{
    return null;
  }
}



