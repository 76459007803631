const context = {
  login: {
    login: {
      en: "Login",
      cn: "登录账号",
    },
    "welcome.message": {
      en: "Welcome back, please login to your account.",
      cn: "欢迎小霸王体育后台！请登录",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    password: {
      en: "Password",
      cn: "密码",
    },
    "user_name.cannot.be.empty": {
      en: "Username cannot be empty.",
      cn: "账户号不能填空",
    },
    "user_pass.cannot.be.empty": {
      en: "Password cannot be empty.",
      cn: "密码不能填空",
    },
    "wrong.user.pass": {
      en: "Wrong Username or Password.",
      cn: "错误账户号或密码",
    },
    "login.exceeded": {
      en: "Login Exceeded.",
      cn: "用户登录过多",
    },
  },
  dashboard: {
    more: {
      en: "More",
      cn: "更多",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    total_chat: {
      en: "Total chat count",
      cn: "弹幕条数",
    },
    watch_count: {
      en: "Total Watch Count",
      cn: "观看总数",
    },
    total_view_count: {
      en: "Total View Count",
      cn: "游览总数",
    },
    average_watch_duration: {
      en: "Avg Watch Duration",
      cn: "平均观看时长",
    },
    total_chatter: {
      en: "Total chatter count - Unique user",
      cn: "弹幕人数",
    },
    highest_view_count: {
      en: "Highest View Count",
      cn: "流量峰值",
    },
    live_view_counts: {
      en: "Live view count",
      cn: "当前在线人数",
    },
    live_view_count: {
      en: "Live view count",
      cn: "流量峰值",
    },
    highest_view_count: {
      en: "Live view count",
      cn: "流量峰值",
    },
    highest_view_count: {
      en: "Live view count",
      cn: "流量峰值",
    },
    highest_view_count: {
      en: "Live view count",
      cn: "流量峰值",
    },
    "search.broadcaster": {
      en: "Search Broadcaster",
      cn: "搜寻主播",
    },
    export: {
      en: "Export",
      cn: "导出",
    },
    stream_duration: {
      en: "Stream Duration(minutes)",
      cn: "总直播时长",
    },
    broadcaster_nickname: {
      en: "Broadcaster Nickname",
      cn: "主播昵称",
    },
    view: {
      en: "View",
      cn: "查看",
    },
    no_live: {
      en: "Currently there's no broadcast in progress",
      cn: "暂时没有直播进行中",
    },
    title: {
      en: "Livestream History Summary",
      cn: "直播历史数据",
    },
    live_title: {
      en: "Livestream Summary",
      cn: "直播数据 ",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    today: {
      en: "Today",
      cn: "今天",
    },
    yesterday: {
      en: "Yesterday",
      cn: "昨天",
    },
    three_days: {
      en: "3 Days Ago",
      cn: "3天前",
    },
    seven_days: {
      en: "7 Days Ago",
      cn: "7天前",
    },
    "search.broadcaster.username": {
      en: "Search broadcaster username or profile name",
      cn: "搜寻主播账户",
    },
    sort_livestream_datetime: {
      en: " Broadcaster History Summary",
      cn: "直播总结 ",
    },
    total_stream_uptime: {
      en: "Total stream uptime",
      cn: "总直播时长",
    },
    "date.from": {
      en: "Date From",
      cn: "日期 (从)",
    },
    "date.to": {
      en: "Date To",
      cn: "日期 (至)",
    },
    average_view: {
      en: "Average viewer count)",
      cn: "总平均观看次数",
    },
    livestream_datetime: {
      en: "Stream Datetime(Start Time)",
      cn: "直播时间(开始)",
    },
    league: {
      en: "League",
      cn: "联赛",
    },
    match: {
      en: "Match",
      cn: "比赛",
    },
    highest_view_count: {
      en: "Highest View Count",
      cn: "流量峰值",
    },
    more: {
      en: "More",
      cn: "更多",
    },
    more: {
      en: "More",
      cn: "更多",
    },
    more: {
      en: "More",
      cn: "更多",
    },
    more: {
      en: "More",
      cn: "更多",
    },
    more: {
      en: "More",
      cn: "更多",
    },
  },
  header: {
    "change.password": {
      en: "Change Password",
      cn: "更改密码",
    },
    "log.out": {
      en: "Log Out",
      cn: "退出",
    },
  },
  "left.nav": {
    dashboard: {
      en: "Dashboard",
      cn: "首页",
    },
    sports: {
      en: "SPORTS",
      cn: "体育",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    lol: {
      en: "LoL",
      cn: "英雄联盟",
    },
    csgo: {
      en: "CSGO",
      cn: "反恐精英:全球攻势",
    },
    dota2: {
      en: "Dota2",
      cn: "刀塔 2",
    },
    kog: {
      en: "KoG",
      cn: "王者荣耀",
    },
    broadcasters: {
      en: "BROADCASTERS",
      cn: "主播控制",
    },
    "leadership.board": {
      en: "Leadership Board",
      cn: "排行榜",
    },
    application: {
      en: "Application",
      cn: "主播申请管理",
    },
    tagline: {
      en: "Tagline",
      cn: "主播头衔",
    },
    members: {
      en: "MEMBERS",
      cn: "小霸王会员资料",
    },
    users: {
      en: "USERS",
      cn: "后台用户管理",
    },
    searching: {
      en: "searching...",
      cn: "搜寻",
    },
    "bc.list": {
      en: "List",
      cn: "主播",
    },
    "member.list": {
      en: "List",
      cn: "会员",
    },
    "user.list": {
      en: "List",
      cn: "管理",
    },
    "op.record": {
      en: "Operation Record",
      cn: "操作记录",
    },
    schedule: {
      en: "Schedule",
      cn: "赛程表",
    },
    "my.profile": {
      en: "My Profile",
      cn: "个人资料",
    },
    "bc.schedule": {
      en: "Schedule",
      cn: "赛程表",
    },
    "bc.replay": {
      en: "Replay",
      cn: "回放列表",
    },
    "predict.history": {
      en: "History",
      cn: "历史记录",
    },
    "predict.statement": {
      en: "Bet Statement",
      cn: "注单",
    },
    "predict.list": {
      en: "List",
      cn: "预测列表",
    },
    "bet.prediction": {
      en: "Bet Prediction",
      cn: "投注预测",
    },
    tracking: {
      en: "Url Tracking",
      cn: "网址追踪",
    },
    "guest.blacklist": {
      en: "Guest List",
      cn: "游客名单",
    },
    "ip.blacklist": {
      en: "IP Blacklist",
      cn: "IP 黑名单",
    },
    setting: {
      en: "Setting",
      cn: "设置",
    },
    "chat.word.block": {
      en: "Word Blocking",
      cn: "敏感词屏蔽",
    },
    announcement: {
      en: "Announcement",
      cn: "公告",
    },
    "chat.room": {
      en: "Chat Room",
      cn: "聊天室",
    },
    "chat.banner": {
      en: "Chat Banner",
      cn: "直播间公告",
    },
    banner: {
      en: "Banner",
      cn: "横幅广告",
    },
    "splash.screen": {
      en: "Splash Screen",
      cn: "App加载页面",
    },
    promotion: {
      en: "Promotion",
      cn: "优惠",
    },
    "fake.member": {
      en: "Fake Member",
      cn: "人机会员",
    },
    "fake.message": {
      en: "Fake Message",
      cn: "弹幕模板",
    },
    "qs.angel": {
      en: "QS Angel",
      cn: "球神天使",
    },
    app_setting: {
      en: "App Settings",
      cn: "APP下载链接",
    },
  },
  sports: {
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    limit: {
      en: "Limit",
      cn: "限制",
    },
    days: {
      en: "days",
      cn: "天数",
    },
    "date.from": {
      en: "Date From",
      cn: "日期 (从)",
    },
    "date.to": {
      en: "Date To",
      cn: "日期 (至)",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    "more.filter.options": {
      en: "More Filter Options",
      cn: "更多过滤选择",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    "select.all": {
      en: "Select All",
      cn: "全选",
    },
    clear: {
      en: "Clear",
      cn: "取消",
    },
    leagues: {
      en: "Leagues",
      cn: "联赛",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    searching: {
      en: "Searching",
      cn: "搜寻",
    },
    schedule: {
      en: "Schedule",
      cn: "体育赛程",
    },
    "search.for.league.name": {
      en: "Search for League and Team Names",
      cn: "搜索联赛名和球队名",
    },
    live: {
      en: "Live",
      cn: "正在直播",
    },
    "tips.predict": {
      en: "Tips & Predict",
      cn: "提示及预测",
    },
    "league.team.name": {
      en: "League and Team Name",
      cn: "联赛名和球队名",
    },
  },
  appsetting: {
    submit: {
      en: "Submit",
      cn: "提交",
    },
    success: {
      en: "Success",
      cn: "成功更改链接",
    },
    success_msg: {
      en: "Your download link has been successfully changed",
      cn: "您的下载链接已成功更改",
    },
  },
  schedule: {
    "hot.match": {
      en: "Hot Match",
      cn: "热门体育赛事",
    },
    broadcasters: {
      en: "Broadcasters",
      cn: "主播",
    },
    "stream.url": {
      en: "Stream URL",
      cn: "视频流源",
    },
    banner: {
      en: "Banner",
      cn: "网页横幅",
    },
    add: {
      en: "Add",
      cn: "添加",
    },
    "add.record": {
      en: "Add Record",
      cn: "添加记录",
    },
    "search.broadcaster": {
      en: "Search Broadcaster",
      cn: "搜寻主播",
    },
    "search.broadcaster.username": {
      en: "Search broadcaster username or profile name",
      cn: "搜寻主播账户",
    },
    ok: {
      en: "Ok",
      cn: "确认",
    },
    live: {
      en: "Live",
      cn: "正在直播",
    },
    remove: {
      en: "Remove",
      cn: "删除",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    upload: {
      en: "Upload",
      cn: "上载",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    "upload.record": {
      en: "Upload Record",
      cn: "上载资料",
    },
    "stream.url.exist": {
      en: "Stream URL exsit in the list.",
      cn: "视频流源已存在",
    },
    "go.live": {
      en: "Go Live",
      cn: "立刻直播",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    date: {
      en: "Date",
      cn: "日期",
    },
    sport: {
      en: "Sport",
      cn: "体育",
    },
    send: {
      en: "Send",
      cn: "发送",
    },
    "blocked.list": {
      en: "Blocked List",
      cn: "屏蔽名单",
    },
    "all.list": {
      en: "All List",
      cn: "全部名单",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    sports: {
      en: "Sports",
      cn: "体育",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    lol: {
      en: "LoL",
      cn: "英雄联盟",
    },
    csgo: {
      en: "CSGO",
      cn: "反恐精英:全球攻势",
    },
    dota2: {
      en: "Dota2",
      cn: "刀塔 2",
    },
    kog: {
      en: "KoG",
      cn: "王者荣耀",
    },
    "stream.key": {
      en: "Stream Key",
      cn: "视频关键",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "取消记录",
    },
    "delete.confirmation": {
      en: "Are you sure you want to delete",
      cn: "请确认取消记录",
    },
    ban: {
      en: "Banner",
      cn: "替换缩图",
    },
  },
  "broadcaster.profile": {
    followers: {
      en: "Followers",
      cn: "粉丝",
    },
    "reset.text": {
      en: "Are you sure you want to reset this password?",
      cn: "是否确认重置登录密码？",
    },
    "reset.default": {
      en: "Reset Password",
      cn: "重置登录密码",
    },
    "reset.success.text": {
      en: "Success reset password for",
      cn: "成功重置密码为",
    },
    success: {
      en: "Success",
      cn: "成功",
    },
    popularity: {
      en: "Popularity",
      cn: "人气",
    },
    "total.broadcast": {
      en: "Total Broadcast",
      cn: "开播总数",
    },
    "win.rate": {
      en: "Win Rate",
      cn: "赢率",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    id: {
      en: "ID",
      cn: "I.D",
    },
    "upload.avatar": {
      en: "Upload Avatar",
      cn: "上载头像",
    },
    "broadcaster.profile": {
      en: "Broadcaster Profile",
      cn: "主播个人资料",
    },
    edit: {
      en: "Edit",
      cn: "编辑",
    },
    tagline: {
      en: "Tagline",
      cn: "头衔",
    },
    nickname: {
      en: "Nickname",
      cn: "昵称",
    },
    email: {
      en: "Email",
      cn: "电邮",
    },
    mobile: {
      en: "Mobile",
      cn: "手机号",
    },

    status: {
      en: "Status",
      cn: "系统状态",
    },
    active: {
      en: "active",
      cn: "活跃",
    },
    disabled: {
      en: "disabled",
      cn: "立刻禁用/拉黑",
    },
    suspended: {
      en: "suspended",
      cn: "暂停",
    },
    categories: {
      en: "Categories",
      cn: "类别",
    },
    region: {
      en: "Region",
      cn: "区域 ",
    },
    china: {
      en: "China",
      cn: "国内 ",
    },
    outside_china: {
      en: "Outside China",
      cn: "国外 ",
    },
    basketball: {
      en: "basketball",
      cn: "篮球",
    },
    esport: {
      en: "esport",
      cn: "电竞",
    },
    football: {
      en: "football",
      cn: "足球",
    },
    "date.created": {
      en: "Date Created",
      cn: "创建日期",
    },
    "about.me": {
      en: "About me",
      cn: "关于我",
    },
    weight: {
      en: "Weight",
      cn: "体重",
    },
    height: {
      en: "Height",
      cn: "高度",
    },
    birthdate: {
      en: "Birthdate",
      cn: "生日",
    },
    biography: {
      en: "Biography",
      cn: "主播个人资料",
    },
    photo: {
      en: "Photo",
      cn: "图片",
    },
    "upload.photo": {
      en: "Upload Photo",
      cn: "上载个人照片",
    },
    "picture.gallery": {
      en: "Picture Gallery",
      cn: "个人图片集",
    },
    "upload.gallery": {
      en: "Upload Gallery",
      cn: "上载个人图片集",
    },
    remove: {
      en: "Remove",
      cn: "删除",
    },
    "update.broadcaster": {
      en: "Update Broadcaster",
      cn: "更新主播",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    "file.size.exceeded": {
      en: "File size exceeded",
      cn: "文件大小超出",
    },
    "upload.failed": {
      en: "Upload Failed, Please contact administrator.",
      cn: "上载失败。请通知管理员。",
    },
    "approval.status": {
      en: "Approval Status",
      cn: "申请状态",
    },
    approve: {
      en: "Approve",
      cn: "通过",
    },
    "personal.info": {
      en: "Personal Information",
      cn: "个人信息",
    },
    name: {
      en: "Name",
      cn: "姓名",
    },
    "profile.id": {
      en: "Identity No",
      cn: "身份号码",
    },
    "bank.no": {
      en: "Bank No",
      cn: "银行号码",
    },
    "social.no": {
      en: "Social",
      cn: "社交号",
    },
    intro: {
      en: "intro",
      cn: "自我介绍",
    },
    "update.personal.info": {
      en: "Update Personal Information",
      cn: "更新主播个人信息",
    },
    credentials: {
      en: "Credentials",
      cn: "证书",
    },
    wallet_balance: {
      en: "Wallet Balance",
      cn: "钱包余额",
    },
    "update.follower": {
      en: "Update Follower",
      cn: "更新粉丝",
    },
    "update.view": {
      en: "Update View",
      cn: "更新人气",
    },
    statement: {
      en: "Statement",
      cn: "账单",
    },
    "fake.member": {
      en: "Fake Member",
      cn: "人机会员",
    },
  },
  "broadcaster.list": {
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    active: {
      en: "Active",
      cn: "活跃",
    },
    disabled: {
      en: "Disabled",
      cn: "立刻禁用/拉黑",
    },
    suspended: {
      en: "Suspended",
      cn: "暂停",
    },
    "broadcaster.list": {
      en: "Broadcaster List",
      cn: "主播名单",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    avatar: {
      en: "Avatar",
      cn: "头像",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    id: {
      en: "Id",
      cn: "I.D",
    },
    category: {
      en: "Category",
      cn: "类别",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    esport: {
      en: "E.Sport",
      cn: "电竞",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    view: {
      en: "View",
      cn: "查看",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "取消记录",
    },
    "delete.confirmation": {
      en: "Are you sure you want to delete",
      cn: "请确认取消记录",
    },
    ok: {
      en: "Ok",
      cn: "输入",
    },
    "add.broadcaster": {
      en: "Add Broadcaster",
      cn: "添加主播",
    },
    password: {
      en: "Password",
      cn: "密码",
    },
    "confirm.password": {
      en: "Confirm Password",
      cn: "确认密码",
    },
    nickname: {
      en: "Nickname",
      cn: "昵称",
    },
    mobile: {
      en: "Mobile",
      cn: "手机号",
    },
    profile_name: {
      en: "Name",
      cn: "姓名",
    },
    profile_id: {
      en: "Identity No",
      cn: "身份号码",
    },
    bank_number: {
      en: "Bank No",
      cn: "银行号码",
    },
    profile_social: {
      en: "Social",
      cn: "社交号",
    },
    introduction: {
      en: "Introduction",
      cn: "自我介绍",
    },
    "pass.min8.max12": {
      en: "Password require 8-2 charactarcters",
      cn: "密码要求最少8-12个字",
    },
    "pass.do.not.match": {
      en: "Password not matched",
      cn: "密码不匹配",
    },

    balance: {
      en: "Balance",
      cn: "余额",
    },
    "add.record": {
      en: "Add",
      cn: "添加主播",
    },
  },
  "broadcaster.schedule": {
    "": {
      en: "",
      cn: "",
    },
    scheduled: {
      en: "Schedule",
      cn: "开播",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },

    date: {
      en: "Date",
      cn: "日期",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    sports: {
      en: "Sports",
      cn: "体育",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    lol: {
      en: "LoL",
      cn: "英雄联盟",
    },
    csgo: {
      en: "CSGO",
      cn: "反恐精英:全球攻势",
    },
    dota2: {
      en: "Dota2",
      cn: "刀塔 2",
    },
    kog: {
      en: "KoG",
      cn: "王者荣耀",
    },
    "go.live": {
      en: "Go Live",
      cn: "立刻直播",
    },
    schedule: {
      en: "Schedule",
      cn: "赛程表",
    },
    export: {
      en: "Export",
      cn: "导出",
    },
    total_views: {
      en: "Total View",
      cn: "浏览总数",
    },
    total_watch: {
      en: "Total Watch",
      cn: "观看总数",
    },
    status: {
      en: "Status",
      cn: "状态",
    },
    notstarted: {
      en: "Not Started",
      cn: "未开赛",
    },
    started: {
      en: "Started",
      cn: "开赛",
    },
    ended: {
      en: "Ended",
      cn: "完场",
    },
    "league.team.name": {
      en: "League and Team Name",
      cn: "联赛名和球队名",
    },
  },
  "broadcaster.application": {
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    avatar: {
      en: "Avatar",
      cn: "头像",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    name: {
      en: "Name",
      cn: "名称",
    },
    id: {
      en: "Id",
      cn: "I.D",
    },
    category: {
      en: "Category",
      cn: "类别",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    view: {
      en: "View",
      cn: "查看",
    },
    active: {
      en: "Active",
      cn: "活跃",
    },
    "bc.application": {
      en: "Broadcaster Application",
      cn: "主播申请管理",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    esport: {
      en: "E.Sport",
      cn: "电竞",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    disabled: {
      en: "Disabled",
      cn: "立刻禁用/拉黑",
    },
    suspended: {
      en: "Suspended",
      cn: "暂停",
    },
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
  },
  "broadcaster.tagline": {
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    active: {
      en: "Active",
      cn: "活跃",
    },
    suspended: {
      en: "Suspended",
      cn: "暂停",
    },
    "bc.tagline.list": {
      en: "Tagline List",
      cn: "主播头衔列表",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    avatar: {
      en: "Avatar",
      cn: "头像",
    },
    "new.tagline": {
      en: "New brodcaster tagline",
      cn: "新主播头衔",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除主播头衔",
    },
    "delete.confirmation": {
      en: "Are you sure you want to delete",
      cn: "确认删除主播头衔称呼",
    },
    ok: {
      en: "Ok",
      cn: "确认",
    },
    "add.tagline": {
      en: "Add Tagline",
      cn: "添加头衔",
    },
    "add.ok": {
      en: "OK",
      cn: "确认",
    },
    edit: {
      en: "Edit",
      cn: "编制",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "update.datetime": {
      en: "Updated DateTime",
      cn: "更新日期时间",
    },
    "tagline.name": {
      en: "Tagline Name",
      cn: "头衔称呼",
    },
    "edit.record": {
      en: "Edit Tagline",
      cn: "编辑主播头衔",
    },
    "bc.tagline": {
      en: "Brodcaster Tagline",
      cn: "主播头衔",
    },
    success: {
      en: "Success",
      cn: "成功",
    },
    "delete.done": {
      en: "System delete broadcaster tagline ",
      cn: "系统已删除主播头衔",
    },
    "edit.done": {
      en: "System edit broadcaster tagline ",
      cn: "系统已更新主播头衔",
    },
    "create.done": {
      en: "System added new broadcaster tagline ",
      cn: "系统已添加主播头衔",
    },
  },
  "member.list": {
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    active: {
      en: "Active",
      cn: "活跃",
    },
    disabled: {
      en: "Disabled",
      cn: "立刻禁用/拉黑",
    },
    suspended: {
      en: "Suspended",
      cn: "暂停",
    },
    "member.list": {
      en: "Member List",
      cn: "会员名单",
    },
    "total.page": {
      en: "Total Pages",
      cn: "总页数",
    },
    "total.item": {
      en: "Total Items",
      cn: "总数量",
    },
    avatar: {
      en: "Avatar",
      cn: "头像",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    name: {
      en: "Name",
      cn: "名称",
    },
    id: {
      en: "Id",
      cn: "I.D",
    },
    category: {
      en: "Category",
      cn: "类别",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    esport: {
      en: "E.Sport",
      cn: "电竞",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    view: {
      en: "View",
      cn: "查看",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "Ok",
      cn: "确认",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "取消记录",
    },
    "delete.confirmation": {
      en: "Are you sure you want to delete",
      cn: "请确认取消记录",
    },
    balance: {
      en: "Wallet Balance",
      cn: "钱包余额",
    },
    nickname: {
      en: "Nickname",
      cn: "昵称",
    },
    fanrank: {
      en: "Fan Rank",
      cn: "粉丝等级",
    },
    date_created: {
      en: "Created Date",
      cn: "创建日期",
    },
    last_login: {
      en: "Last Login",
      cn: "最后登陆",
    },
    "date.type": {
      en: "Date Type",
      cn: "日期类型",
    },
    last_active: {
      en: "Last Active",
      cn: "最后活跃",
    },
    "date.from": {
      en: "Date From",
      cn: "日期 (从)",
    },
    "date.to": {
      en: "Date To",
      cn: "日期 (至)",
    },
  },
  "member.profile": {
    username: {
      en: "Username",
      cn: "会员账户号",
    },
    id: {
      en: "ID",
      cn: "I.D",
    },
    ip: {
      en: "IP Address",
      cn: "IP 地址",
    },
    "member.profile": {
      en: "Member Profile",
      cn: "会员资料",
    },
    nickname: {
      en: "Nickname",
      cn: "昵称",
    },
    email: {
      en: "Email",
      cn: "电邮",
    },
    mobile: {
      en: "Mobile",
      cn: "手机号",
    },
    fanpoint: {
      en: "Fan Points",
      cn: "会员积分",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    role: {
      en: "Role",
      cn: "权限",
    },
    none: {
      en: "None",
      cn: "无权限",
    },
    manager: {
      en: "Manager",
      cn: "房管",
    },
    supervisor: {
      en: "Supervisor",
      cn: "超管",
    },
    active: {
      en: "active",
      cn: "活跃",
    },
    disabled: {
      en: "disabled",
      cn: "立刻禁用/拉黑",
    },
    suspended: {
      en: "suspended",
      cn: "暂停",
    },
    "date.created": {
      en: "Date Created",
      cn: "创建日期",
    },
    last_active: {
      en: "Last Active",
      cn: "最后活跃",
    },
    last_login: {
      en: "Last Login",
      cn: "最后登录",
    },
    balance: {
      en: "Balance",
      cn: "余额",
    },
    wallet_balance: {
      en: "Wallet Balance",
      cn: "钱包余额",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    deposit: {
      en: "Deposit",
      cn: "存款",
    },
    withdraw: {
      en: "Withdraw",
      cn: "提款",
    },
    success: {
      en: "Success",
      cn: "成功",
    },
    "login.reset": {
      en: "Reset Login",
      cn: "重置登录",
    },
    reset_success_message: {
      en: "Success to reset login password to ",
      cn: "成功重置密码为 ",
    },
    "reset.message": {
      en: "Are you sure want to reset login password ?",
      cn: "是否确认重置登录密码？",
    },
    "reset.password": {
      en: "Reset Login Password",
      cn: "重置登录密码",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "reset.default": {
      en: "Reset Password",
      cn: "重置密码",
    },
  },
  "user.list": {
    "user.manage": {
      en: "User Management",
      cn: "后台用户管理",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    name: {
      en: "Name",
      cn: "名称",
    },
    permission: {
      en: "Permission",
      cn: "系统权限",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    "reset.password": {
      en: "Reset Password",
      cn: "重设密码",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "add.record": {
      en: "Add Record",
      cn: "加资料",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新资料",
    },
    admin: {
      en: "Admin",
      cn: "管理",
    },
    member: {
      en: "Member",
      cn: "会员",
    },
    team_manager: {
      en: "Team Manager",
      cn: "团队管理",
    },
    manager: {
      en: "Manager",
      cn: "经理",
    },
    success: {
      en: "Success",
      cn: "成功",
    },
    "password.reset.123": {
      en: "Password has been reset to abc123 for admin",
      cn: "密码以更改成 abc123",
    },
    ok: {
      en: "Ok",
      cn: "确定",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除资料",
    },
    "delete.message": {
      en: "Are you sure you want to delete",
      cn: "是否确认删除",
    },
    password: {
      en: "Password",
      cn: "密码",
    },
    "confirm.password": {
      en: "Confirm Password",
      cn: "确认密码",
    },
    optional: {
      en: "Optional",
      cn: "自选",
    },
    "password.successfully.changed": {
      en: "Password successfully changed.",
      cn: "密码成功更改",
    },
    "pass.min8.max12": {
      en: "Password require 8-2 charactarcters",
      cn: "密码要求最少8-12个字",
    },
    "pass.do.not.match": {
      en: "Password not matched",
      cn: "密码不匹配",
    },
    "reset.message": {
      en: "Are you sure want to reset password for",
      cn: "是否确认重设密码 -",
    },
  },
  "operation.record": {
    submit: {
      en: "Submit",
      cn: "提交",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    "op.record": {
      en: "Operation Record",
      cn: "操作记录",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    user_id: {
      en: "User Id",
      cn: "账户号",
    },
    username: {
      en: "Userame",
      cn: "名称",
    },
    permission: {
      en: "Permission",
      cn: "系统权限",
    },
    log_key: {
      en: "Log Key",
      cn: " 管理行动",
    },
    date_created: {
      en: "Date Created",
      cn: "日期/时间",
    },
  },

  "qs.angel": {
    "qs.angel": {
      en: "QS Angel",
      cn: "球神天使",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "取消记录",
    },
    "delete.confirmation": {
      en: "Are you sure you want to delete",
      cn: "请确认取消记录",
    },
    "pixels.recommended": {
      en: "Image Pixel Recommend",
      cn: "最佳图像像素",
    },
    "upload.image": {
      en: "Upload Image",
      cn: "上传照片",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    name: {
      en: "Name",
      cn: "名称",
    },
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    upload: {
      en: "Upload",
      cn: "上载",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    title: {
      en: "Title",
      cn: "标题",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    date: {
      en: "Date",
      cn: "日期",
    },
    thumbnail: {
      en: "Thumbnail Image",
      cn: "缩图",
    },
    video: {
      en: "Video",
      cn: "视频",
    },
  },
  "change.password": {
    "change.password": {
      en: "Change Password",
      cn: "更改密码",
    },
    current_password: {
      en: "Current Password",
      cn: "当前密码",
    },
    password: {
      en: "Password",
      cn: "新密码",
    },
    "confirm.password": {
      en: "Confirm Password",
      cn: "确认密码",
    },
    "update.password": {
      en: "Update Password",
      cn: "更新密码",
    },
    success: {
      en: "Success",
      cn: "成功",
    },
    "password.successfully.changed": {
      en: "Password successfully changed.",
      cn: "更新密码成功",
    },
    ok: {
      en: "Ok",
      cn: "确定",
    },
    error: {
      en: "Error",
      cn: "错误密码",
    },
    "password.cannot.be.empty": {
      en: "Password cannot be empty.",
      cn: "密码不能为空。",
    },
    "confirm.password.cannot.be.empty": {
      en: "Confirm Password cannot be empty.",
      cn: "密码肯定不能为空。",
    },
    "password.min.max": {
      en: "Password must be minimum of 8 and maximum of 13",
      cn: "密码必须最少8个，最多14个字符",
    },
    "password.not.match": {
      en: "Password do not match",
      cn: "密码不匹配",
    },
    "password.reset.123": {
      en: "Password has been reset to abc123",
      cn: "密码以更改成 abc123",
    },
  },
  predict: {
    filters: {
      en: "Filters",
      cn: "过滤",
    },
    no_result: {
      en: "No Result",
      cn: "待更新",
    },
    win: {
      en: "Win",
      cn: "已命中",
    },
    lose: {
      en: "Lose",
      cn: "未命中",
    },
    draw: {
      en: "Draw",
      cn: "走水",
    },
    limit: {
      en: "Limit",
      cn: "限制",
    },
    days: {
      en: "days",
      cn: "天数",
    },
    "date.from": {
      en: "Date From",
      cn: "日期 (从)",
    },
    "date.to": {
      en: "Date To",
      cn: "日期 (至)",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    "more.filter.options": {
      en: "More Filter Options",
      cn: "更多过滤选择",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    "select.all": {
      en: "Select All",
      cn: "全选",
    },
    clear: {
      en: "Clear",
      cn: "取消",
    },
    leagues: {
      en: "Leagues",
      cn: "联赛",
    },
    "total.page": {
      en: "Total",
      cn: "总数",
    },
    searching: {
      en: "Searching",
      cn: "搜寻",
    },
    "token.wallet": {
      en: "Token Wallet",
      cn: "钱包",
    },
    today: {
      en: "Today",
      cn: "今天",
    },
    "make.bet": {
      en: "Make a Bet",
      cn: "下注",
    },
    "upload.image": {
      en: "Upload Image",
      cn: "上传照片",
    },
    title: {
      en: "Title",
      cn: "标题",
    },
    description: {
      en: "Description",
      cn: "描述",
    },
    article: {
      en: "Article",
      cn: "内容",
    },
    upload: {
      en: "Upload",
      cn: "上传",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    sports: {
      en: "Sports",
      cn: "体育",
    },
    football: {
      en: "Football",
      cn: "足球",
    },
    basketball: {
      en: "Basketball",
      cn: "篮球",
    },
    lol: {
      en: "LoL",
      cn: "英雄联盟",
    },
    csgo: {
      en: "CSGO",
      cn: "反恐精英:全球攻势",
    },
    dota2: {
      en: "Dota2",
      cn: "刀塔 2",
    },
    kog: {
      en: "KoG",
      cn: "王者荣耀",
    },
    view: {
      en: "View",
      cn: "查看",
    },
    preview: {
      en: "Preview",
      cn: "预览",
    },
    publish: {
      en: "Publish",
      cn: "刊登",
    },
    save: {
      en: "Save",
      cn: "保存",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    id: {
      en: "I.D",
      cn: "I.D",
    },
    nickname: {
      en: "Nickname",
      cn: "昵称",
    },
    published: {
      en: "Published",
      cn: "已出版",
    },
    unpublished: {
      en: "Unpublished",
      cn: "已下架",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "delete.tip": {
      en: "Delete Tip",
      cn: "删除预测",
    },
    "delete.message": {
      en: "Are you sure to remove this tips",
      cn: "确认删除次预测",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "update.tip.status": {
      en: "Update Tip Status",
      cn: "更新预测状态",
    },
    "update.status": {
      en: "Are you sure update status to",
      cn: "确认更新预测状态为",
    },
    "create.tip": {
      en: "Create Tip",
      cn: "添加预测",
    },
    "create.success": {
      en: "Save Tip Successfully",
      cn: "成功保存预测",
    },
    "create.no.record.found": {
      en: "Cannot found this tip",
      cn: "无此预测记录",
    },
    "create.title.already.exist": {
      en: "Title Exist",
      cn: "标题已存在",
    },
    "pixels.recommended": {
      en: "Image Pixel Recommend",
      cn: "最佳图像像素",
    },
  },
  tracking: {
    url: {
      en: "URL",
      cn: "网址",
    },
    tracking_id: {
      en: "Tracking ID",
      cn: "追踪 I.D",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    "tracking.url": {
      en: "Url Tracking",
      cn: "网址追踪",
    },
  },
  "ip.blacklist": {
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    "ip.blacklist": {
      en: "IP Blacklist",
      cn: "IP 黑名单",
    },
    "search.ip": {
      en: "Search",
      cn: "搜查",
    },
    username: {
      en: "Username",
      cn: "会员账户号",
    },
    ip: {
      en: "IP",
      cn: "IP",
    },
    "date.created": {
      en: "Date Created",
      cn: "创建日期",
    },
    "ban.by": {
      en: "Ban By",
      cn: "拉黑者",
    },
    action: {
      en: "Action",
      cn: "选项",
    },
    "cannot.empty": {
      en: "Can not be empty",
      cn: "不能为空",
    },
    error: {
      en: "Error",
      cn: "错误",
    },
    "not.found": {
      en: "IP not found",
      cn: "IP 不存在",
    },
  },
  "guest.blacklist": {
    "delete.record": {
      en: "Delete and Blacklist",
      cn: "删除 + 拉黑",
    },
    delete: {
      en: "Delete + 拉黑",
      cn: "删除 + 拉黑",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "delete.message": {
      en: "Are you sure want to delete + blacklist this guest?",
      cn: "你确定是否要删除 + 拉黑此游客？",
    },
    "guest.blacklist": {
      en: "Guest List",
      cn: "游客名单",
    },
    "search.guest": {
      en: "Search (IP / Username)",
      cn: "搜查 (IP / 用户名)",
    },
    username: {
      en: "Username",
      cn: "游客户名",
    },
    ip: {
      en: "IP",
      cn: "IP",
    },
    "date.created": {
      en: "Date Created",
      cn: "创建日期",
    },
    action: {
      en: "Action",
      cn: "选项",
    },
    "cannot.empty": {
      en: "Can not be empty",
      cn: "不能为空",
    },
    error: {
      en: "Error",
      cn: "错误",
    },
    "not.found": {
      en: "IP not found",
      cn: "游客不存在",
    },
    "total.page": {
      en: "Total Page",
      cn: "总页面",
    },
    "total.item": {
      en: "Total Guest",
      cn: "总游客数量",
    },
  },
  "splash.screen": {
    "splash.screen": {
      en: "Splash Screen",
      cn: "APP 加载页面",
    },
    "date.created": {
      en: "Last Updated",
      cn: "最后更新",
    },
    type: {
      en: "Type",
      cn: "分类",
    },
    data: {
      en: "Data",
      cn: "内容",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新数据",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "banner.1": {
      en: "Banner 1",
      cn: "加载页 1",
    },
    "banner.2": {
      en: "Banner 2",
      cn: "加载页 2",
    },
    "banner.3": {
      en: "Banner 3",
      cn: "加载页 3",
    },
    image: {
      en: "Image",
      cn: "图片",
    },
    "pixels.recommended": {
      en: "Pixel recommended",
      cn: "建议图片的解析度",
    },
    description: {
      en: "Description",
      cn: "口号",
    },
    newsticker: {
      en: "Newsticker (marquees)",
      cn: "跑马灯",
    },
    marquee: {
      en: "Marquee",
      cn: "跑马灯",
    },
    "no.data": {
      en: "Data Error",
      cn: "数据错误",
    },
    action: {
      en: "Action",
      cn: "选项",
    },
    title: {
      en: "Title",
      cn: "标题",
    },
    "url.download": {
      en: "Download URL",
      cn: "下载网址",
    },
    upload: {
      en: "upload",
      cn: "上传",
    },
    error: {
      en: "Error",
      cn: "错误",
    },
  },
  banner: {
    banner: {
      en: "Banner",
      cn: "横幅广告",
    },
    code: {
      en: "Code",
      cn: "编号",
    },
    title: {
      en: "Title",
      cn: "标题",
    },
    description: {
      en: "Description",
      cn: "描述",
    },
    "date.created": {
      en: "Created Date",
      cn: "创建日期",
    },
    "web.link": {
      en: "Web Link",
      cn: "网页链接",
    },
    "web.image": {
      en: "Web Image",
      cn: "网页图片",
    },
    "mobile.link": {
      en: "Mobile Link",
      cn: "手机链接",
    },
    "mobile.image": {
      en: "Mobile Image",
      cn: "手机图片",
    },
    "app.link": {
      en: "APP Link",
      cn: "APP链接",
    },
    "app.image": {
      en: "APP Image",
      cn: "APP图片",
    },
    "pixels.recommended": {
      en: "Image Pixel Recommend",
      cn: "最佳图像像素",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    web: {
      en: "Web",
      cn: "网页",
    },
    mobile: {
      en: "Mobile",
      cn: "手机",
    },
    app: {
      en: "APP",
      cn: "APP",
    },
    upload: {
      en: "Upload",
      cn: "上传",
    },
    "title.already.exist": {
      en: "Code already exist",
      cn: "标题已存在",
    },
    "code.already.exist": {
      en: "",
      cn: "编号已存在",
    },
  },
  "chat.banner": {
    "code.name": {
      en: "Code Name",
      cn: "代号",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    title: {
      en: "Title",
      cn: "标题",
    },
    description: {
      en: "Description",
      cn: "描述",
    },
    "date.created": {
      en: "Created Date",
      cn: "创建日期",
    },
    broadcaster: {
      en: "Broadcaster",
      cn: "主播",
    },
    "value.one": {
      en: "Value 1",
      cn: "账号",
    },
    "value.two": {
      en: "Value 2",
      cn: "复制链接",
    },
    "label.one": {
      en: "Label 1",
      cn: "标签 1",
    },
    "label.two": {
      en: "Label 2",
      cn: "标签 2",
    },
    "label.three": {
      en: "Label 3",
      cn: "标签 3",
    },
    upload: {
      en: "Upload",
      cn: "上传",
    },
  },
  promotion: {
    promotion: {
      en: "Promotion",
      cn: "优惠",
    },
    code: {
      en: "Code",
      cn: "编号",
    },
    title: {
      en: "Title",
      cn: "标题",
    },
    description: {
      en: "Description",
      cn: "描述",
    },
    "date.created": {
      en: "Created Date",
      cn: "创建日期",
    },
    "date.start": {
      en: "Date Start",
      cn: "开始日期",
    },
    "date.end": {
      en: "Date End",
      cn: "结束日期",
    },
    status: {
      en: "Status",
      cn: "状态",
    },
    published: {
      en: "Published",
      cn: "出版",
    },
    unpublished: {
      en: "Unpublished",
      cn: "下架",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "add.record": {
      en: "Add Record",
      cn: "添加记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "promotion.detail": {
      en: "Promotion Detail",
      cn: "优惠详情",
    },
    "effective.date": {
      en: "Effective Date",
      cn: "有效日期",
    },
    "bg.color": {
      en: "Background Color",
      cn: "背景颜色",
    },
    image: {
      en: "Image",
      cn: "图片",
    },
    "main.image": {
      en: "Main Image",
      cn: "主图片",
    },
    "sub.image": {
      en: "Sub Image",
      cn: "次图片",
    },
    "pixels.recommended": {
      en: "Image Pixels Recommend",
      cn: "最佳图像像素",
    },
    context: {
      en: "Context",
      cn: "优惠内容",
    },
    create: {
      en: "Create",
      cn: "创建",
    },
    upload: {
      en: "Upload",
      cn: "上传",
    },
    "choose.color": {
      en: "Choose Color",
      cn: "选择颜色",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "create.promotion": {
      en: "Create Promotion",
      cn: "创建优惠",
    },
    to: {
      en: "To",
      cn: "至",
    },
    "create.success": {
      en: "Create Success",
      cn: "创建成功",
    },
    "title.already.exist": {
      en: "Title already exist",
      cn: "标题已存在",
    },
    "code.already.exist": {
      en: "Code already exist",
      cn: "编号已存在",
    },
    "invalid.date": {
      en: "Invalid effective date",
      cn: "有效日期无效",
    },
    "update.promotion.status": {
      en: "Update Promotion Status",
      cn: "更新优惠状态",
    },
    "update.status": {
      en: "Update Status to",
      cn: "更新状态为",
    },
    "edit.promotion": {
      en: "Edit Promotion",
      cn: "编辑优惠",
    },
    "edit.success": {
      en: "Edit Success",
      cn: "编辑成功",
    },
    "title.cannot.be.empty": {
      en: "Title cannot be empty",
      cn: "标题不能为空",
    },
    "code.cannot.be.empty": {
      en: "Code cannot be empty",
      cn: "编号不能为空",
    },
  },
  "fake.message": {
    "fake.message": {
      en: "Fake Message",
      cn: "假信息",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "add.record": {
      en: "Add Record",
      cn: "添加记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    "date.created": {
      en: "Created Date",
      cn: "创建日期",
    },
    message: {
      en: "Message",
      cn: "信息",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    "message.cannot.be.empty": {
      en: "Message cannot be empty",
      cn: "信息不能为空",
    },
  },
  "fake.member": {
    "fake.member": {
      en: "Fake Member",
      cn: "人机会员",
    },
    username: {
      en: "Username",
      cn: "账户号",
    },
    id: {
      en: "ID",
      cn: "I.D",
    },
    nickname: {
      en: "Nickname",
      cn: "昵称",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    "add.record": {
      en: "Add Record",
      cn: "添加记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    avatar: {
      en: "Avatar",
      cn: "头像",
    },
    "assigned.to": {
      en: "Assigned To",
      cn: "分配",
    },
    status: {
      en: "Status",
      cn: "系统状态",
    },
    search: {
      en: "Search",
      cn: "搜寻",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    all: {
      en: "All",
      cn: "全部",
    },
    active: {
      en: "Active",
      cn: "活跃",
    },
    disabled: {
      en: "Disabled",
      cn: "立刻禁用/拉黑",
    },
    suspended: {
      en: "Suspended",
      cn: "暂停",
    },
    "date.created": {
      en: "Created Date",
      cn: "创建日期",
    },
    "user_name.cannot.be.empty": {
      en: "Username cannot be empty",
      cn: "账户号不能为空",
    },
    "nickname.cannot.be.empty": {
      en: "Nickname cannot be empty",
      cn: "昵称不能为空",
    },
    "avatar.cannot.be.empty": {
      en: "Avatar cannot be empty",
      cn: "请选择头像",
    },
  },
  misc: {
    loading: {
      en: "Loading",
      cn: "正在努力",
    },
  },
  wallet: {
    "wallet.adjustment": {
      en: "Wallet Adjustment",
      cn: "余额调整",
    },
    amount: {
      en: "Amount",
      cn: "金额",
    },
    submit: {
      en: "Submit",
      cn: "提交",
    },
    withdraw: {
      en: "Withdraw",
      cn: "提款",
    },
    deposit: {
      en: "Deposit",
      cn: "存款",
    },
    type: {
      en: "Type",
      cn: "类别",
    },
    statement: {
      en: "Statement",
      cn: "账单",
    },
    "total.page": {
      en: "Total page",
      cn: "总页数",
    },
    "previous.balance": {
      en: "Previous Balance",
      cn: "账变余额",
    },
    "statement.status": {
      en: "Status",
      cn: "状态",
    },
    no: {
      en: "No.",
      cn: "No.",
    },
    wallet_balance: {
      en: "Wallet Balance",
      cn: "钱包余额",
    },
    "transaction.datetime": {
      en: "Date Time",
      cn: "交易时间",
    },
    success: {
      en: "Success",
      cn: "成功",
    },
    all: {
      en: "All",
      cn: "全部",
    },
  },
  "setting.wordblock": {
    "chat.word.block": {
      en: "Word Blocking",
      cn: "字眼屏蔽",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    text: {
      en: "Text",
      cn: "字眼",
    },
  },
  "announcement.chatroom": {
    "chat.room": {
      en: "Chat Room",
      cn: "聊天室",
    },
    "add.record": {
      en: "Add New",
      cn: "添加",
    },
    "update.record": {
      en: "Update Record",
      cn: "更新记录",
    },
    "delete.record": {
      en: "Delete Record",
      cn: "删除记录",
    },
    update: {
      en: "Update",
      cn: "更新",
    },
    delete: {
      en: "Delete",
      cn: "删除",
    },
    ok: {
      en: "OK",
      cn: "确认",
    },
    action: {
      en: "Action",
      cn: "系统行动",
    },
    "delete.message": {
      en: "Are you sure want to delete this?",
      cn: "你确定是否要删除？",
    },
    message: {
      en: "Message",
      cn: "讯息",
    },
    send: {
      en: "Send Message",
      cn: "发送讯息",
    },
    "send.message": {
      en: "Are you sure want to send this message?",
      cn: "你确定是否要发送讯息?",
    },
  },
};
const trans = {
  helper: (page, value, lang) => {
    // console.log(page, value, lang);
    // console.log(trans[page][value]);
    if (!trans[page] || !trans[page][value]) {
      console.log("translation error", { page: page, value: value });
      return value;
    }
    return trans[page][value][lang];
  },
  ...context,
};
export default trans;
// helper: (page, value, lang) => {
//   // console.log(page, value, lang);
//   // console.log(trans[page][value]);
//   if(!trans[page][value]){
//     console.log('translation error', {page: page, value: value});
//     return value;
//   }
//   return trans[page][value][lang];
// },
