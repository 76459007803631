import { observable, action } from 'mobx';

const state = observable({
  update: action((key, value) => {
    state[key] = value;
  }),
  version: '1',
  lang: 'en',
  regions: {
    en: {
      key: 'en',
      name: 'English'
    },
    cn: {
      key: 'cn',
      name: 'Chinese'
    }
  }
});

export default state;
