import {
  helper_removeSet,
  helper_localSet,
  helper_localGet,
  def_lang,
  date_day_count,
} from "src/helper";
import { fetch_post, fetch_get } from "src/helper/api";
import modal_state from "src/components/modal/state";
import translate from "src/helper/translation";
const page = "change.password";
const lang = def_lang();

export async function login(post) {
  fetch_post("/users/login", post).then((value) => {
    if (value.status) {
      console.log(value);
      helper_localSet("auth", value.result);
      if (value.result.permission === "broadcaster") {
        window.location =
          "/" + lang + "/schedule/list/all/" + date_day_count(0) + "/1";
      } else if (value.result.permission === "team_manager") {
        window.location =
          "/" +
          lang +
          "/broadcasters/schedule/all/" +
          date_day_count(0) +
          "/all/1";
      } else {
        window.location = "/" + lang + "/dashboard/1";
      }
      return true;
    }

    modal_state.update("error", {
      ...modal_state.error,
      is_open: true,
      title: "Error",
      message: translate.helper(page, value.system, lang),
    });
    return false;
  });
}
export async function logout() {
  let user = helper_localGet("auth");
  if (user.permission === "broadcaster") {
    fetch_get("/members/logout").then((value) => {
      if (value.status) {
        console.log(value);
        helper_removeSet("auth");
        window.location = "/" + lang;
        return true;
      }
      modal_state.update("error", {
        ...modal_state.error,
        is_open: true,
        title: "Error",
        message: translate.helper(page, value.system, lang),
      });
      return false;
    });
    return true;
  }

  fetch_get("/users/logout").then((value) => {
    if (value.status) {
      console.log(value);
      helper_removeSet("auth");
      window.location = "/" + lang;
      return true;
    }

    modal_state.update("error", {
      ...modal_state.error,
      is_open: true,
      title: "Error",
      message: translate.helper(page, value.system, lang),
    });
    return false;
  });
}
